import React, { useState } from "react";
import Lottie from "lottie-react";
import TransactionCompleteAnim from "../../utils/transactionCompleteAnimation.json";
import { useTranslation } from "react-i18next";
import RateMerchant from "./components/RateMerchant";
import { useQueryString } from "hooks";
import DownloadApps from "./components/DownloadApps";
import styles from "./styles.module.css";
import PendingIcon from "assets/icons/PendingIcon";
import {
  // commonServiceInstance,
  getCommisionForTips,
  // getTransactionDetails,
  postTransactionAction,
} from "api";
import { useEffect } from "react";
import Loader from "components/Loader";
import PDFDownloadButton from "./PDFDownloadButton";

const SuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const [commisionPerncet, setCommisionPercent] = useState();

  const { t } = useTranslation();

  const { parseQuery: parsedQuery } = useQueryString();
  const {
    acquiringTransactionId: transactionId = "",
    amount = "",
    receiverName = "",
    status,
    ratingEnabled = "false",
    tipsEnabled = "false",
    receiverType = "",
    currency = "GEL",
    receiverId: receiverUserId,
    paymentProviderEnum,
    paymentMethod = "STANDARD",
    currencyRate: currRate = 0,
    integratorOrderId: integratorOrderIdOB = "",
  } = parsedQuery;

  const shouldTip = !!JSON.parse(tipsEnabled.toLowerCase());
  const shouldRate = !!JSON.parse(ratingEnabled.toLowerCase());
  const decodedString = decodeURIComponent(receiverName);
  const userName = decodedString.replace(/\+/g, " ");

  useEffect(() => {
    (async () => {
      if (shouldTip || shouldRate) {
        setLoading(false);
        return;
      }
      const integratorOrderId =
        localStorage.getItem("integratorOrderId") || integratorOrderIdOB;
      if (integratorOrderId) {
        try {
          const { redirectRequired, successRedirectUri } =
            await postTransactionAction(integratorOrderId);
          if (redirectRequired && successRedirectUri) {
            window.location.href = successRedirectUri;
          }
        } finally {
          localStorage.removeItem("integratorOrderId");
          setLoading(false);
        }
      }
      setLoading(false);
    })();
  }, []);

  var currentDate = new Date();
  var formattedDate =
    ("0" + (currentDate.getMonth() + 1)).slice(-2) +
    "." +
    ("0" + currentDate.getDate()).slice(-2) +
    "." +
    currentDate.getFullYear() +
    " " +
    ("0" + currentDate.getHours()).slice(-2) +
    ":" +
    ("0" + currentDate.getMinutes()).slice(-2);

  // useEffect(() => {
  //   const getCommision = async () => {
  //     const { senderCommissionPercent } = await getCommisionForTips();
  //     setCommisionPercent(senderCommissionPercent);
  //   };

  //   getCommision();
  // }, []);

  useEffect(() => {
    const getCommision = async () => {
      const reciverId =
        localStorage.getItem("receiverUserId") || receiverUserId;
      const { senderCommissionPercent } = await getCommisionForTips(
        receiverUserId,
        receiverType,
        transactionId
      );
      setCommisionPercent(senderCommissionPercent);
    };

    getCommision();
    return () => {
      localStorage.removeItem("receiverUserId");
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  const handleReviewDone = () => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const receiverName = url.searchParams.get("receiverName");
    const amount = url.searchParams.get("amount");
    const newURL = `${url.origin}${url.pathname}?receiverName=${receiverName}&amount=${amount}&tipsEnabled=false&acquiringTransactionId=${transactionId}&ratingEnabled=false&integratorOrderId=${integratorOrderIdOB}`;
    window.location.href = newURL;
  };

  const currencyRate = Number(currRate);

  let content = shouldRate ? (
    <div className="w-full text-center">
      <RateMerchant
        commisionAmount={commisionPerncet}
        amount={amount}
        transactionId={transactionId}
        onReviewDone={handleReviewDone}
        tipsEnabled={shouldTip}
        receiverName={userName}
        receiverType={receiverType}
        currency={currency}
        currencyRate={currencyRate}
        paymentProviderEnum={paymentProviderEnum}
        paymentMethod={paymentMethod}
        integratorOrderIdOB={integratorOrderIdOB}
      />
    </div>
  ) : (
    <DownloadApps
      commisionAmount={commisionPerncet}
      amount={amount}
      transactionId={transactionId}
      tipsEnabled={shouldTip}
      receiverName={userName}
      receiverType={receiverType}
      skip={handleReviewDone}
      currency={currency}
      currencyRate={currencyRate}
      paymentProviderEnum={paymentProviderEnum}
      paymentMethod={paymentMethod}
      integratorOrderIdOB={integratorOrderIdOB}
    />
  );

  return (
    <div
      className={`${shouldRate && styles.ratingCont} flex flex-col gap-0 `}
      style={{ height: "100%" }}
    >
      <PDFDownloadButton
        transactionId={transactionId}
        paymentProviderEnum={paymentProviderEnum}
        currency={currency}
        shouldRate={shouldRate}
      />
      <div className="h-3/4 flex flex-col items-center  gap-5 text-center mt-[15px] justify-center  ">
        <div>
          {status !== "IN_PROGRESS" ? (
            <Lottie
              animationData={TransactionCompleteAnim}
              loop={false}
              style={{ width: "100px", height: "100px" }}
            />
          ) : (
            <PendingIcon />
          )}
        </div>
        <div style={{ transform: "translateY(-30px)", marginTop: "20px" }}>
          <h3 className="font-bold text-lg text-center">
            {status !== "IN_PROGRESS"
              ? t("description.transactionComplete")
              : t("paymentProcessing")}
          </h3>
          <p className="opacity-50 text-center whitespace-pre-line">
            {status == "IN_PROGRESS" ? (
              t("description.paymentProcessing")
            ) : amount && userName ? (
              <>
                <p className="w-[270px] mt-[10px]">
                  {t("description.paymentSuccessfulInfo", {
                    amount: amount,
                    currency: currency == "EUR" ? "€" : "₾",
                  })}{" "}
                  {userName + "  "} {formattedDate}
                </p>
              </>
            ) : (
              t("description.paymentSuccessful")
            )}
          </p>
        </div>
      </div>

      <div className="w-full flex flex-col justify-end">{content}</div>
    </div>
  );
};

export default SuccessPage;
